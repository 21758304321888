<template>
    <div class="p-3">
        <b-row> 
            <b-col xl="4" lg="6" md="12"> <!-- Events -->
                <b-row>
                    <b-col cols="12"><h4>Events</h4></b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`events-fetchHistory`"
                            v-model="obj.api.events.fetchHistory"
                            :name="`events-fetchHistory`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Fetch event history for given entity and it's children
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`events-fetchTest`"
                            v-model="obj.api.events.fetchTest"
                            :name="`events-fetchTest`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Fetch test data
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`events-createTest`"
                            v-model="obj.api.events.createTest"
                            :name="`events-createTest`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Create or Enter a test
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`events-fetchReplacement`"
                            v-model="obj.api.events.fetchReplacement"
                            :name="`events-fetchReplacement`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Fetch replacement/install data
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`events-createReplacement`"
                            v-model="obj.api.events.createReplacement"
                            :name="`events-createReplacement`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Create or Enter a replacement/install
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`events-fetchSurvey`"
                            v-model="obj.api.events.fetchSurvey"
                            :name="`events-fetchSurvey`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Fetch survey/minor correction data
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`events-fetchTaskComment`"
                            v-model="obj.api.events.fetchTaskComment"
                            :name="`events-fetchTaskComment`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Fetch task comment data
                        </b-form-checkbox>
                    </b-col>
                </b-row>
            </b-col>
            <b-col xl="4" lg="6" md="12"> <!-- Technicians -->
                <b-row>
                    <b-col cols="12"><h4>Technicains</h4></b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`technicians-list`"
                            v-model="obj.api.technicians.list"
                            :name="`technicians-list`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            List all technicians
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`technicians-fetch`"
                            v-model="obj.api.technicians.fetch"
                            :name="`technicians-fetch`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Fetch single technician data
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`technicians-update`"
                            v-model="obj.api.technicians.update"
                            :name="`technicians-update`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Update technician data
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`technicians-create`"
                            v-model="obj.api.technicians.create"
                            :name="`technicians-create`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Create a new technician
                        </b-form-checkbox>
                    </b-col>
                </b-row>
            </b-col>
            <b-col xl="4" lg="6" md="12"> <!-- Device Models -->
                <b-row>
                    <b-col cols="12"><h4>Device Models</h4></b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`deviceModels-list`"
                            v-model="obj.api.deviceModels.list"
                            :name="`deviceModels-list`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            List all device models
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`deviceModels-fetch`"
                            v-model="obj.api.deviceModels.fetch"
                            :name="`deviceModels-fetch`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Fetch single device model
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`deviceModels-update`"
                            v-model="obj.api.deviceModels.update"
                            :name="`deviceModels-update`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Update device models
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`deviceModels-create`"
                            v-model="obj.api.deviceModels.create"
                            :name="`deviceModels-create`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Create a new device model
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`deviceModels-delete`"
                            v-model="obj.api.deviceModels.delete"
                            :name="`deviceModels-delete`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Delete a device model
                        </b-form-checkbox>
                    </b-col>
                </b-row>  
            </b-col>
            <b-col v-for="(choice, index) in choiceListKeys" :key="`choice-list-${index}`" xl="4" lg="6" md="12"> <!-- All Choice Lists -->
                <b-row>
                    <b-col cols="12"><h4>{{choiceListNames[index]}}</h4></b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`${choiceListNames[index]}-list`"
                            v-model="obj.api[choice].list"
                            :name="`${choiceListNames[index]}-list`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            List all {{choiceListNames[index]}} (Required for choice list selections)
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`${choiceListNames[index]}-fetch`"
                            v-model="obj.api[choice].fetch"
                            :name="`${choiceListNames[index]}-fetch`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Fetch a {{choiceListNameSingular[index]}}
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`${choiceListNames[index]}-update`"
                            v-model="obj.api[choice].update"
                            :name="`${choiceListNames[index]}-update`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Update a {{choiceListNameSingular[index]}}
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`${choiceListNames[index]}-create`"
                            v-model="obj.api[choice].create"
                            :name="`${choiceListNames[index]}-create`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Create a new {{choiceListNameSingular[index]}}
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`${choiceListNames[index]}-delete`"
                            v-model="obj.api[choice].delete"
                            :name="`${choiceListNames[index]}-delete`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Delete a {{choiceListNameSingular[index]}}
                        </b-form-checkbox>
                    </b-col>
                </b-row>   
            </b-col>
            <b-col xl="4" lg="6" md="12"> <!-- Fulcrum -->
                <b-row>
                    <b-col cols="12"><h4>Fulcrum</h4></b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`fulcrum-issue`"
                            v-model="obj.api.fulcrum.issue"
                            :name="`fulcrum-issue`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Issue record to Fulcrum
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`fulcrum-register`"
                            v-model="obj.api.fulcrum.register"
                            :name="`fulcrum-register`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Register and connect a Fulcrum account
                        </b-form-checkbox>
                    </b-col>
                </b-row>
            </b-col>
            <b-col xl="4" lg="6" md="12"> <!-- Closeout -->
                <b-row>
                    <b-col cols="12"><h4>Closeout</h4></b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`closeout-list`"
                            v-model="obj.api.closeout.list"
                            :name="`closeout-list`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can list closeout records
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`closeout-updateClosed`"
                            v-model="obj.api.closeout.updateClosed"
                            :name="`closeout-updateClosed`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can mark closed / close for a closeout record
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`closeout-updateDownloaded`"
                            v-model="obj.api.closeout.updateDownloaded"
                            :name="`closeout-updateDownloaded`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can download / mark downloaded for a closeout record
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`closeout-updateSent`"
                            v-model="obj.api.closeout.updateSent"
                            :name="`closeout-updateSent`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can send / mark sent for a closeout record
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`closeout-updateBilled`"
                            v-model="obj.api.closeout.updateBilled"
                            :name="`closeout-updateBilled`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can mark billed for a closeout record
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                        <b-form-checkbox
                            class="ml-4"
                            :id="`closeout-updatePaid`"
                            v-model="obj.api.closeout.updatePaid"
                            :name="`closeout-updatePaid`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can mark paid for a closeout record
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                        <b-form-checkbox
                            class="ml-4"
                            :id="`closeout-allowDisable`"
                            v-model="obj.api.closeout.allowDisable"
                            :name="`closeout-allowDisable`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can disable a row in the closeouts table
                        </b-form-checkbox>
                    </b-col>
                </b-row>
            </b-col>
            <b-col xl="4" lg="6" md="12"> <!-- Official PDF Management -->
                <b-row>
                    <b-col cols="12"><h4>Official PDF Management</h4></b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`generateOfficialPDF-generate`"
                            v-model="obj.api.generateOfficialPDF.generate"
                            :name="`generateOfficialPDF-generate`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can manually generate offical PDF test reports
                        </b-form-checkbox>
                    </b-col>
                </b-row>
            </b-col>
            <b-col xl="4" lg="6" md="12"> <!-- Flags -->
                <b-row>
                    <b-col cols="12"><h4>Flags</h4></b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`flags-fetch`"
                            v-model="obj.api.flags.fetch"
                            :name="`flags-fetch`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can fetch list of flags for a given parent id
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`flags-create`"
                            v-model="obj.api.flags.create"
                            :name="`flags-create`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can create new flags
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`flags-delete`"
                            v-model="obj.api.flags.delete"
                            :name="`flags-delete`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can delete flags
                        </b-form-checkbox>
                    </b-col>
                </b-row>
            </b-col>
            <b-col xl="4" lg="6" md="12"> <!-- List Builder -->
                <b-row>
                    <b-col cols="12"><h4>List Builder</h4></b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`listBuilder-run`"
                            v-model="obj.api.listBuilder.run"
                            :name="`listBuilder-run`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can run list builder lists
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`listBuilder-list`"
                            v-model="obj.api.listBuilder.list"
                            :name="`listBuilder-list`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can list saved list builder lists
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`listBuilder-fetch`"
                            v-model="obj.api.listBuilder.fetch"
                            :name="`listBuilder-fetch`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can load a list builder list
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`listBuilder-update`"
                            v-model="obj.api.listBuilder.update"
                            :name="`listBuilder-update`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can create or save updates to a list builder list
                        </b-form-checkbox>
                    </b-col>
                </b-row>
            </b-col>
            <b-col xl="4" lg="6" md="12"> <!-- Emails -->
                <b-row>
                    <b-col cols="12"><h4>Emails</h4></b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`emails-list`"
                            v-model="obj.api.email.list"
                            :name="`emails-list`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can list all saved email templates
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`emails-fetch`"
                            v-model="obj.api.email.fetch"
                            :name="`emails-fetch`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can fetch a single email template
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`emails-update`"
                            v-model="obj.api.email.update"
                            :name="`emails-update`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can create or update an email template
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                        <b-form-checkbox
                            class="ml-4"
                            :id="`emails-send`"
                            v-model="obj.api.email.send"
                            :name="`emails-send`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can send an email batch from a template
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                        <b-form-checkbox
                            class="ml-4"
                            :id="`emails-sendTest`"
                            v-model="obj.api.email.sendTest"
                            :name="`emails-sendTest`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can email a test report
                        </b-form-checkbox>
                    </b-col>
                </b-row>
            </b-col>
            <b-col xl="4" lg="6" md="12"> <!-- Letters -->
                <b-row>
                    <b-col cols="12"><h4>Letters</h4></b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`letters-list`"
                            v-model="obj.api.letter.list"
                            :name="`letter-list`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can list all saved letter templates
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`letters-fetch`"
                            v-model="obj.api.letter.fetch"
                            :name="`letters-fetch`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can fetch a single letter template
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`letters-update`"
                            v-model="obj.api.letter.update"
                            :name="`letters-update`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can create or update a letter template
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`letters-send`"
                            v-model="obj.api.letter.send"
                            :name="`letters-send`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can generate a letter batch from a template
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`letters-batchDownload`"
                            v-model="obj.api.letter.batchDownload"
                            :name="`letters-batchDownload`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can mark a batch of generated letters as downloaded
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`letters-batchSend`"
                            v-model="obj.api.letter.batchSend"
                            :name="`letters-batchSend`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can mark a batch of generated letters as sent
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`letters-batchDismiss`"
                            v-model="obj.api.letter.batchDismiss"
                            :name="`letters-batchDismiss`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can dismiss a sent batch of generated letters
                        </b-form-checkbox>
                    </b-col>
                </b-row>
            </b-col>
            <b-col xl="4" lg="6" md="12"> <!-- Forms -->
                <b-row>
                    <b-col cols="12"><h4>Forms</h4></b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`forms-list`"
                            v-model="obj.api.forms.list"
                            :name="`forms-list`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can list all forms template
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`forms-update`"
                            v-model="obj.api.forms.update"
                            :name="`forms-update`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can update a form template
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`forms-delete`"
                            v-model="obj.api.forms.delete"
                            :name="`forms-delete`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can delete a form template
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`forms-use`"
                            v-model="obj.api.forms.use"
                            :name="`forms-use`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can use forms (required for most functionality)
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`forms-save`"
                            v-model="obj.api.forms.save"
                            :name="`forms-save`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can save changes to data when using a form
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`forms-builder`"
                            v-model="obj.api.forms.builder"
                            :name="`forms-builder`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can build, create new, or update custom forms
                        </b-form-checkbox>
                    </b-col>
                </b-row>
            </b-col>
            <b-col xl="4" lg="6" md="12"> <!-- Customers -->
                <b-row>
                    <b-col cols="12"><h4>Customers</h4></b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`customers-list`"
                            v-model="obj.api.customers.list"
                            :name="`customers-list`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can list all customers
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`customers-fetch`"
                            v-model="obj.api.customers.fetch"
                            :name="`customers-fetch`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can fetch a single customer
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`customers-update`"
                            v-model="obj.api.customers.update"
                            :name="`customers-update`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can update customers
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`customers-create`"
                            v-model="obj.api.customers.create"
                            :name="`customers-create`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can create a new customer
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`customers-delete`"
                            v-model="obj.api.customers.delete"
                            :name="`customers-delete`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can delete a customer
                        </b-form-checkbox>
                    </b-col>
                </b-row>
            </b-col>
            <b-col xl="4" lg="6" md="12"> <!-- Sites -->
                <b-row>
                    <b-col cols="12"><h4>Sites</h4></b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`sites-list`"
                            v-model="obj.api.sites.list"
                            :name="`sites-list`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can list all sites
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`sites-fetch`"
                            v-model="obj.api.sites.fetch"
                            :name="`sites-fetch`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can fetch a single site
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`sites-update`"
                            v-model="obj.api.sites.update"
                            :name="`sites-update`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can update sites
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`sites-create`"
                            v-model="obj.api.sites.create"
                            :name="`sites-create`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can create a new site
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`sites-delete`"
                            v-model="obj.api.sites.delete"
                            :name="`sites-delete`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can delete a site
                        </b-form-checkbox>
                    </b-col>
                </b-row>
            </b-col>
            <b-col xl="4" lg="6" md="12"> <!-- Connections -->
                <b-row>
                    <b-col cols="12"><h4>Connections</h4></b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`connections-list`"
                            v-model="obj.api.connections.list"
                            :name="`connections-list`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can list all connections
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`connections-fetch`"
                            v-model="obj.api.connections.fetch"
                            :name="`connections-fetch`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can fetch a single connection
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`connections-update`"
                            v-model="obj.api.connections.update"
                            :name="`connections-update`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can update connections
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`connections-create`"
                            v-model="obj.api.connections.create"
                            :name="`connections-create`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can create a new connection
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`connections-delete`"
                            v-model="obj.api.connections.delete"
                            :name="`connections-delete`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can delete a connection
                        </b-form-checkbox>
                    </b-col>
                </b-row>
            </b-col>
            <b-col xl="4" lg="6" md="12"> <!-- Devices -->
               <b-row>
                    <b-col cols="12"><h4>Devices</h4></b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`devices-list`"
                            v-model="obj.api.devices.list"
                            :name="`devices-list`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can list all devices
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`devices-fetch`"
                            v-model="obj.api.devices.fetch"
                            :name="`devices-fetch`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can fetch a single device
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`devices-update`"
                            v-model="obj.api.devices.update"
                            :name="`devices-update`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can update devices
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`devices-create`"
                            v-model="obj.api.devices.create"
                            :name="`devices-create`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can create a new device
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`devices-delete`"
                            v-model="obj.api.devices.delete"
                            :name="`devices-delete`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can delete a device
                        </b-form-checkbox>
                    </b-col>
                </b-row> 
            </b-col>
            <b-col xl="4" lg="6" md="12"> <!-- Bulk -->
               <b-row>
                    <b-col cols="12"><h4>Bulk Create/Update</h4></b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`bulk-view`"
                            v-model="obj.api.bulk.view"
                            :name="`bulk-view`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can View Bulk Sessions
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                    <b-form-checkbox
                            class="ml-4"
                            :id="`bulk-perform`"
                            v-model="obj.api.bulk.perform"
                            :name="`bulk-perform`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can Create or Update Records In Bulk
                        </b-form-checkbox>
                    </b-col>
                </b-row> 
            </b-col>
            <b-col xl="4" lg="6" md="12"> <!-- Account Management -->
               <b-row>
                    <b-col cols="12"><h4>Account Management</h4></b-col>
                    <b-col cols="12">
                        <b-form-checkbox
                            class="ml-4"
                            :id="`accountManagement-listAccountUsers`"
                            v-model="obj.api.accountManagement.listAccountUsers"
                            :name="`accountManagement-listAccountUsers`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can List Account Users (Recommended For All Users)
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                        <b-form-checkbox
                            class="ml-4"
                            :id="`accountManagement-createAccountUsers`"
                            v-model="obj.api.accountManagement.createAccountUsers"
                            :name="`accountManagement-createAccountUsers`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can Create New Account Users <b-icon-question-circle id="accountManagement-createAccountUsers-help"></b-icon-question-circle>
                                <b-popover 
                                    target="accountManagement-createAccountUsers-help" 
                                    triggers="hover" 
                                    placement="top" 
                                    boundary="window"
                                >
                                    <template #title><b>This May Affect Billing</b></template>
                                    Addining more users may affect your monthly or yearly billing.
                                </b-popover>
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                        <b-form-checkbox
                            class="ml-4"
                            :id="`accountManagement-updateAccountUsers`"
                            v-model="obj.api.accountManagement.updateAccountUsers"
                            :name="`accountManagement-updateAccountUsers`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can Update Account Users <b-icon-question-circle id="accountManagement-updateAccountUsers-help"></b-icon-question-circle>
                                <b-popover 
                                    target="accountManagement-updateAccountUsers-help" 
                                    triggers="hover" 
                                    placement="top" 
                                    boundary="window"
                                >
                                    <template #title>Update Account Users Details</template>
                                    Allows the user to modify other account users, freezing accounts, and modifying permisssions.
                                    <hr>
                                    <b>Note:</b> Regardless of this setting, every user is allowed to update <em>their own</em> first name, last name, and display name.
                                </b-popover>
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                        <b-form-checkbox
                            class="ml-4"
                            :id="`accountManagement-viewPortalRuleset`"
                            v-model="obj.api.accountManagement.viewPortalRuleset"
                            :name="`accountManagement-viewPortalRuleset`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can View The Ruleset For Automatic Portal Submission Processing
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                        <b-form-checkbox
                            class="ml-4"
                            :id="`accountManagement-updatePortalRuleset`"
                            v-model="obj.api.accountManagement.updatePortalRuleset"
                            :name="`accountManagement-updatePortalRuleset`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can Alter The Ruleset For Automatic Portal Submission Processing
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                        <b-form-checkbox
                            class="ml-4"
                            :id="`accountManagement-viewAccountBilling`"
                            v-model="obj.api.accountManagement.viewAccountBilling"
                            :name="`accountManagement-viewAccountBilling`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can View Account Billing Information, Invoices, and Amounts Paid
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                        <b-form-checkbox
                            class="ml-4"
                            :id="`accountManagement-updateAccountBilling`"
                            v-model="obj.api.accountManagement.updateAccountBilling"
                            :name="`accountManagement-updateAccountBilling`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can Alter Account Billing Information <b-icon-question-circle id="accountManagement-updateAccountBilling-help"></b-icon-question-circle>
                                <b-popover 
                                    target="accountManagement-updateAccountBilling-help" 
                                    triggers="hover" 
                                    placement="top" 
                                    boundary="window"
                                >
                                    <template #title>What Does This Include</template>
                                    This user is allowed to modify the account billing, including adding, updating, and removing payment methods. 
                                    Altering where billing invoices are sent and managing the emails which recieve copies of invoices.
                                </b-popover>
                        </b-form-checkbox>
                    </b-col>
                </b-row> 
            </b-col>
            <b-col xl="4" lg="6" md="12"> <!-- Files -->
                <b-row>
                    <b-col cols="12"><h4>Files</h4></b-col>
                    <b-col cols="12">
                        <b-form-checkbox
                            class="ml-4"
                            :id="`files-fetch`"
                            v-model="obj.file.files.fetch"
                            :name="`files-fetch`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can Download Files (Requried for many essential functions)
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                        <b-form-checkbox
                            class="ml-4"
                            :id="`files-upload`"
                            v-model="obj.file.files.upload"
                            :name="`files-upload`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can upload new files
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                        <b-form-checkbox
                            class="ml-4"
                            :id="`files-update`"
                            v-model="obj.file.files.update"
                            :name="`files-update`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can update an existing file, uploading a new file to replace an old file
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                        <b-form-checkbox
                            class="ml-4"
                            :id="`files-delete`"
                            v-model="obj.file.files.delete"
                            :name="`files-delete`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can delete files
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                        <b-form-checkbox
                            class="ml-4"
                            :id="`files-meta`"
                            v-model="obj.file.files.meta"
                            :name="`files-meta`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can fetch file meta data (Required for essential functions)
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12">
                        <b-form-checkbox
                            class="ml-4"
                            :id="`files-zip`"
                            v-model="obj.file.files.zip"
                            :name="`files-zip`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Can fetch multiple files in a zip archive
                        </b-form-checkbox>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <div v-if="showResults">
            <b-button @click="generateEndpointJSON">Create Endpoint Control JSON</b-button>
            <b-button @click="setAllFalse">Deny All Permissions</b-button>
            <b-button @click="setAllTrue">Grant All Permissions</b-button>
            <b-form-textarea
                id="textarea"
                v-model="json"
                rows="3"
                max-rows="500"
            ></b-form-textarea>
        </div>
        <div v-else class="d-flex flex-row-reverse">
            <b-button variant="success" @click="generateEndpointJSON">Save Endpoint Control Permissions</b-button>
        </div>
    </div>
</template>

<script>
const butils = require('../../libs/basicUtils.js');
const _ = require('underscore');

export default {
    name: 'endpointpermissionsbuilder',
    components:{

    },
    props:{
        grantAdmin:{
            type: Boolean,
            default: () => { return false }
        },
        showResults:{
            type: Boolean,
            default: () => { return false }
        },
        onCompleted:{
            type: Function,
            default: (val) => {
                console.log(`EPC No OnCompleted Callback Prop Provided JSON: `)
                console.log(val);
            }
        }
    },
    data(){
        return{
            json: null,
            choiceListKeys: ["connection_types", "grade_types", "hazard_levels", "hazard_types", "protection_types", "service_types", "site_types", "device_types", "device_manufacturers", "group_names", "product_codes", "notice_types"],
            choiceListNames: ["Connection Types","Grade Types","Hazard Levels","Hazard Types","Protection Types","Service Types","Site Types","Device Types","Device Manufacturers","Group Names","Product Codes","Notice Types"],
            choiceListNameSingular: ["Connection Type","Grade Type","Hazard Level","Hazard Type","Protection Type","Service Type","Site Type","Device Type","Device Manufacturer","Group Name","Product Code","Notice Type"],
            obj: {
                api:{
                    events: {
                        fetchHistory: true,
                        fetchTest: true,
                        createTest: true,
                        fetchReplacement: true,
                        createReplacement: true,
                        fetchSurvey: true,
                        fetchTaskComment: true
                    },
                    technicians: {
                        list: true,
                        fetch: true,
                        update: true,
                        create: true,
                    },
                    deviceModels: {
                        list: true,
                        fetch: true,
                        update: true,
                        create: true,
                        delete: true
                    },
                    fulcrum: {
                        issue: true,
                        register: false
                    },
                    closeout: {
                        list: true,
                        updateClosed: true,
                        updateDownloaded: true,
                        updateSent: true,
                        updateBilled: true,
                        updatePaid: true,
                        allowDisable: false
                    },
                    generateOfficialPDF: {
                        generate: false,
                    },
                    flags: {
                        fetch: true,
                        create: true,
                        delete: true
                    },
                    listBuilder: {
                        run: true,
                        list: true,
                        fetch: true,
                        update: true
                    },
                    email: {
                        list: true,
                        fetch: true,
                        update: true,
                        send: true,
                        sendTest: true
                    },
                    letter: {
                        list: true,
                        fetch: true,
                        update: true,
                        send: true,
                        batchDownload: true,
                        batchSend: true,
                        batchDismiss: true
                    },
                    forms: {
                        list: true,
                        update: false,
                        delete: false,
                        use: true,
                        save: true,
                        builder: false
                    },
                    customers: {
                        list: true,
                        fetch: true,
                        create: true,
                        update: true,
                        delete: false
                    },
                    sites: {
                        list: true,
                        fetch: true,
                        create: true,
                        update: true,
                        delete: false
                    },
                    connections: {
                        list: true,
                        fetch: true,
                        create: true,
                        update: false,
                        delete: false
                    },
                    devices: {
                        list: true,
                        fetch: true,
                        create: true,
                        update: false,
                        delete: false
                    },
                    utilities: {
                        list: true,
                        fetch: true,
                        create: true,
                        update: true,
                        delete: false
                    },
                    bulk: {
                        view: false,
                        perform: false,
                    },
                    accountManagement: {
                        listAccountUsers:true,
                        createAccountUsers:false,
                        updateAccountUsers:false,
                        viewPortalRuleset:false,
                        updatePortalRuleset:false,
                        viewAccountBilling:false,
                        updateAccountBilling:false
                    }
                },
                file: {
                    files: {
                        fetch: true,
                        upload: true,
                        update: true,
                        delete: true,
                        meta: true,
                        zip: true
                    }
                },
            },
            setupCompleted: null
        }
    },
    methods:{
        getJSONOnly(){
            return JSON.stringify(this.obj);
        },
        generateEndpointJSON(){
            this.json = JSON.stringify(this.obj);
            this.onCompleted(this.json);
        },
        setAllFalse(){
            var topLevelKeys = _.keys(this.obj);
            topLevelKeys.forEach(key=>{
                var endpointKeys = _.keys(this.obj[key]);
                endpointKeys.forEach(endpoint=>{
                    var actionKeys = _.keys(this.obj[key][endpoint]);
                    actionKeys.forEach((actionKey)=>{
                        this.obj[key][endpoint][actionKey] = false;
                    })
                })
            })
        },
        setAllTrue(){
            var topLevelKeys = _.keys(this.obj);
            topLevelKeys.forEach(key=>{
                var endpointKeys = _.keys(this.obj[key]);
                endpointKeys.forEach(endpoint=>{
                    var actionKeys = _.keys(this.obj[key][endpoint]);
                    actionKeys.forEach((actionKey)=>{
                        this.obj[key][endpoint][actionKey] = true;
                    })
                })
            })
        },
        performSetup(){
            this.setupCompleted = new Promise((resolve, reject)=>{
                // Check if this account has a fulcrum link
                butils.instance.get(`${process.env.VUE_APP_API_BASE_URL}/atils/has_fulcrum_link.json`)
                .then((response)=>{
                    // console.log(`Has Fulcrum Link: ${response.data.result.hasFulcrumLink}`)
                    if(!response.data.result.hasFulcrumLink){
                        // Revoke Closeouts
                        this.obj.api.closeout = _.mapObject(this.obj.api.closeout, (val, key)=>{
                            return false;
                        })
                        // Revoke Fulcrum
                        this.obj.api.fulcrum.issue = false;
                        this.obj.api.fulcrum.register = this.grantAdmin;
                    }
                    resolve();
                    this.generateEndpointJSON();
                })
                .catch((err)=>{
                    console.log(err);
                    reject(err);
                })
            })
            
            // Allow Register Fulcrum Link
            this.obj.api.fulcrum.register = this.grantAdmin;
            // Allow Generate Official PDF
            this.obj.api.generateOfficialPDF.generate = this.grantAdmin;
            // Grant Forms
            this.obj.api.forms.update = this.grantAdmin;
            this.obj.api.forms.delete = this.grantAdmin;
            this.obj.api.forms.builder = this.grantAdmin;
            // Grant Bulk Record Management
            this.obj.api.bulk.view = this.grantAdmin;
            this.obj.api.bulk.perform = this.grantAdmin;
            // Grant All Account Management
            this.obj.api.accountManagement = _.mapObject(this.obj.api.accountManagement, (val, key)=>{
                return this.grantAdmin;
            })
            this.obj.api.accountManagement.listAccountUsers = true;
        }
    },
    watch:{
        grantAdmin(newVal, oldVal){
            this.performSetup();
        }
    },
    computed:{

    },
    beforeCreate(){

    },
    created(){

    },
    beforeMount(){
        this.performSetup();
        // Only do this once
        this.choiceListKeys.forEach((key)=>{
            this.obj.api[key] = {
                list: true,
                fetch: true,
                update: true,
                create: true,
                delete: false
            }
        })
    },
    mounted(){

    },
    beforeUpdate(){

    },
    updated(){

    },
    beforeDestroy(){

    },
    destroyed(){

    }
}
</script>

<style scoped>
    
</style>